import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useAppBridge, TitleBar } from "@shopify/app-bridge-react";
import Spinner from "../LoadingIndicator/Spinner";
import Turbolinks from "turbolinks";

export type Props = {
  root: string;
  loadPath?: string;
  redirectUri?: string;
  embedded?: boolean;
  installation?: boolean;
};

export default function ShopifyApp({ root, redirectUri, embedded, installation, loadPath }: Props) {
  const classes = useStyles();
  const shopify = useAppBridge(); // Initialize App Bridge instance

  useEffect(() => {
    // Redirect after data loading is complete
    shopify?.ready.then(()=> {
      shopify.idToken().then((idToken)=>{
        document.addEventListener('turbolinks:request-start', async (event: any) => {
          const xhr = event?.data?.xhr;
          // Add the JWT to the Authorization header
          if (idToken) xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
        });

        if (embedded && installation) {
          open(redirectUri, '_top');
        }
        else {
          // Pass token to your backend for session initialization
          fetch(`${root}/shopify/session`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${idToken}`,
              'Content-Type': 'application/json',
            },
          }).then(() => {
            Turbolinks.visit(loadPath);
          });
        }
      });
    });
  }, [shopify]); // Trigger effect once app is ready

  return (
    <div className={classes.root}>
      <TitleBar title="AirRobe Connector" />
      <Spinner width={54}/>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    position: "fixed",
    background: "#000",
    opacity: 0.5,
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    animation: "$fadeIn30 1s normal forwards",
  },
  "@keyframes fadeIn30": {
    "0%": {
      opacity: "0%",
    },
    "100%": {
      opacity: "50%",
    },
  },
});
